import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@services/http.service';
import { EndpointParams } from '@models/utils/endpoint-params';
import { PaymentCreateResponse } from '@models/buy/response/payment-create-response';
import { ProductBuy } from '@views/private/models/member-fee/product-buy';
import { Enrollment } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class RatesEnrollmentService extends HttpService {
    private readonly defaultEndpointParams: EndpointParams = { app: 'payments', version: 1 };

    private enrollmentSubject = new BehaviorSubject<Enrollment[]>([]);
    private isLoadingDataSubject = new BehaviorSubject<boolean>(true);
    private dataStore: { fees: Enrollment[] } = { fees: [] };

    readonly enrollments = this.enrollmentSubject.asObservable();
    readonly loadingData = this.isLoadingDataSubject.asObservable();

    list(): void {
        this.loading(true);
        this.listFees().subscribe(
            () => {
                this.next();
                this.loading(false);
            }
        );
    }

    search(idProduct: string): Observable<any> {
        return this.get(`fee/enrollment/member/${idProduct}`, this.defaultEndpointParams);
    }

    create(productBuy: ProductBuy): Observable<PaymentCreateResponse> { // TODO: change buy
        return this.post('fee/enrollment/member', this.defaultEndpointParams, productBuy);
    }

    createManual(productBuyManual: ProductBuy): Observable<boolean> {
        return this.post('fee/enrollment/member/manual', this.defaultEndpointParams, productBuyManual);
    }

    private listFees(): Observable<boolean> {
        return new Observable<boolean>(
            observable => {
                this.get('fee/enrollment/member', this.defaultEndpointParams).subscribe(
                    data => {
                        this.dataStore.fees = [];
                        data.forEach((payment: any) => {
                            this.dataStore.fees.push(new Enrollment().build(payment));
                        });

                        this.dataStore.fees.sort((a, b) => a.order - b.order);
                        observable.next(true);
                    }, () => observable.next(false)
                );
            }
        );
    }

    private next(): void {
        this.enrollmentSubject.next([ ...this.dataStore.fees ]);
    }

    private loading(value: boolean): void {
        this.isLoadingDataSubject.next(value);
    }
}
