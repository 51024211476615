import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PaymentFlowState } from '@models/payment-flow/payment-flow-state';
import { SessionService } from '@services/utils/session.service';
import { PaymentProcessState } from '@views/payment/models/payment-process/payment-process-state';
import { PaymentProcessStep } from '@views/payment/enumerate/payment-process-step';
import { CONTAINER_FEE } from '@models/fee/fee-injector';
import { Combo } from '@models/fee/combo/combo';
import { SignupFormType } from '@enumerates/setting/signup-form-type';
import { SubscriptionType } from '@enumerates/fee/subscription-type';

import * as paymentProcessActions from '@views/payment/reducers/payment-process.action';
import * as paymentFlowActions from '../../../reducers/payment-flow/payment-flow.action';

@Component({
    selector: 'app-fee-detail-combo',
    templateUrl: './fee-detail-combo.component.html'
})
export class FeeDetailComboComponent {
    readonly currency = this.sessionService.getCenterInfo()?.currency;

    constructor(@Inject(CONTAINER_FEE) public fee: Combo,
                private router: Router,
                private sessionService: SessionService,
                private store: Store<{ paymentProcess: PaymentProcessState; paymentFlow: PaymentFlowState }>) { }

    viewDetails(): void {
        this.router.navigate([`/info/c/${this.fee.id}`]);
    }

    viewRegister(): void {
        let isProrated = false;
        const recurrent = this.fee.fees.find(c => c.idSubscriptionPlanPrice);
        if (recurrent) {
            isProrated = recurrent.isProrated;
        }
        this.store.dispatch(paymentFlowActions.setCombo({
            idCombo: this.fee.id,
            title: this.fee.title,
            price: this.fee.oneTimePrice,
            subtotal: this.fee.subtotal,
            itemPrice: this.fee.itemPrice,
            isProrated,
            oneTimePrice: this.fee.fees.filter(c => !c.idSubscriptionPlanPrice).reduce((total, { price }) => total + price, 0),
            recurrentPrice: recurrent ? recurrent.price : 0,
            subscriptionType: recurrent ? recurrent.priceType : SubscriptionType.puntual,
            comboFees: this.fee.fees
        }));

        this.sessionService.isLogged.pipe(take(1)).subscribe((isLogged) => {
            if (isLogged || this.sessionService.getIsTokenNoSession()) {
                if (this.sessionService.getCenterInfo().signupSetting.signupFormType === SignupFormType.multiple && !this.sessionService.getToken().session) {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.data }));
                } else {
                    this.store.dispatch(paymentProcessActions.setCurrentStep({ step: PaymentProcessStep.confirm }));
                }
                this.router.navigateByUrl('payment/process');
            } else {
                this.router.navigateByUrl('register');
            }
        });
    }
}
