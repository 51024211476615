import { ErrorHandler, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NotificationService } from '@services/utils/notification.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private notificationService: NotificationService, private translateService: TranslateService) {
        super();
    }

    handleError(error: any): void {
        if (!error) {
            return;
        }

        const chunkFailedMessage = /Loading chunk [\s\S]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            this.notificationService.showErrorMessage(
                this.translateService.instant('loading_chunk_failed')
            );
            const redirectOnChunkFail = sessionStorage.getItem(
                'redirectOnChunkFail'
            );
            if (!redirectOnChunkFail) {
                sessionStorage.setItem('redirectOnChunkFail', 'true');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
        super.handleError(error);
    }
}
