<ng-template #indicatorTemplate>
    <i nz-icon nzType="loading" [nzTheme]="'outline'" style="font-size: 24px; display: block;"></i>
  </ng-template>
  
  <nz-spin [nzIndicator]="indicatorTemplate" [nzSpinning]="isLoading | async">
      <div class="fees-container">
          <ng-container *ngIf="combos$ | async as combos;">
              <ng-container *ngFor="let combo of combos">
                  <app-fee-detail [fee]="combo"></app-fee-detail>
              </ng-container>
              
              <ng-container *ngIf="combos.length == 0">
                  <div nz-row nzJustify="center" nzAlign="middle" style="height: 350px;">
                      <div nz-col nzSpan="8">
                          <h2 style="text-align: center;">{{ 'no_combos_show' | translate }}</h2>
                      </div>
                  </div>
              </ng-container>
          </ng-container>
      </div>
  </nz-spin>
  