import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Component, Injector, Input, OnChanges } from '@angular/core';

import { Fee } from '@models/fee/fee';
import { FeeType } from '@enumerates/fee/fee-type';

import { CONTAINER_FEE } from '@models/fee/fee-injector';
import { FeeDetailOneTimeComponent } from './onetime/fee-detail-onetime.component';
import { FeeDetailSubscriptionComponent } from './subscription/fee-detail-subscription.component';
import { FeeDetailProductComponent } from './product/fee-detail-product.component';
import { FeeDetailComboComponent } from './combo/fee-detail-combo.component';
import { FeeDetailEnrollmentComponent } from './enrollment/fee-detail-enrollment.component';

@Component({
    selector: 'app-fee-detail',
    templateUrl: './fee-detail.component.html'
})
export class FeeDetailComponent implements OnChanges {
    @Input() fee!: Fee;

    selectedPortal!: ComponentPortal<any>;

    constructor(private injector: Injector) { }

    ngOnChanges(): void {
        if (this.fee?.type !== null) {
            const component: ComponentType<any> = this.getComponent(this.fee.type);

            this.selectedPortal = new ComponentPortal(component, null, this.createInjector(this.fee));
        }
    }

    getComponent(type: FeeType): ComponentType<any> {
        if (type === FeeType.oneTime) {
            return FeeDetailOneTimeComponent;
        }

        if (type === FeeType.subscription) {
            return FeeDetailSubscriptionComponent;
        }

        if (type === FeeType.product) {
            return FeeDetailProductComponent;
        }

        if (type === FeeType.combo) {
            return FeeDetailComboComponent;
        }

        if (type === FeeType.enrollment) {
            return FeeDetailEnrollmentComponent;
        }

        return FeeDetailOneTimeComponent;
    }

    createInjector(data: Fee): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: CONTAINER_FEE, useValue: data }
            ]
        });
    }
}
