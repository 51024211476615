import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { Combo } from '@models/fee/combo/combo';
import { RatesComboService } from '@services/rates/rates-combo.service';

@Component({
    selector: 'app-rates-combos',
    templateUrl: './rates-combos.component.html',
    styleUrls: ['./rates-combos.component.scss']
})
export class RatesCombosComponent {
    combos$!: Observable<Combo[]>;
    isLoading!: Observable<boolean>;

    constructor(comboService: RatesComboService) {
        this.combos$ = comboService.combos;
        this.isLoading = comboService.loadingData;
    }
}
