import { Fee } from '../fee';

export class Combo extends Fee {
    price!: number;

    get oneTimePrice(): number {
        return this.price;
    }

    build(content: any): this {
        super.build(content);

        this.price = content.price;
        this.fees = content.fees;

        return this;
    }
}
