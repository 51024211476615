import { SubscriptionType } from '@enumerates/fee/subscription-type';
import { PaymentMethodType } from '@enumerates/payment-flow/payment-method-type/payment-method-type';
import { CouponCodeInfo } from '@models/coupon/coupon-code-info';
import { ComboFee } from '@models/fee/combo/combo-fee';
import { PaymentProrate } from '@models/prorate/payment-prorate';
import { Tax } from '@models/tax/tax';
import { createAction, props } from '@ngrx/store';

export const setCard = createAction(
    '[Payment Flow] set card',
    props<{
        idCard: string;
        brand: string;
        last4: string;
    }>()
);

export const setSepaDebit = createAction(
    '[Payment Flow] set sepa debit',
    props<{
        idSepaDebit: string;
        country: string;
        last4: string;
    }>()
);

export const setPaymentMethod = createAction(
    '[Payment Flow] set payment method',
    props<{ idPaymentMethod: string; name: string }>()
);

export const setPaymentMethodType = createAction(
    '[Payment Flow] set payment method type',
    props<{ paymentMethodType: PaymentMethodType }>()
);

export const cancelPaymentMethodFlow = createAction(
    '[Payment Flow] cancel payment method flow'
);

export const setCreditPack = createAction(
    '[Payment Flow] set credit pack',
    props<{
        idCreditPack: string;
        amount: number;
        totalPrice: number;
        subtotal: number;
        tax: Tax;
        amountTax: number;
    }>()
);

export const setSubscriptionPlan = createAction(
    '[Payment Flow] set subscription plan',
    props<{
        idSubscriptionPlan: string;
        idSubscriptionPlanPrice: string;
        title: string;
        price: number;
        isProrated: boolean;
        subscriptionType: SubscriptionType;
        isFreeTrial: boolean;
        daysFreeTrial: number;
        isVirtual: boolean;
        subtotal: number;
        tax: Tax;
        amountTax: number;
        itemPrice: number;
    }>()
);

export const setSubscriptionPlanDiscount = createAction(
    '[Payment Flow] set subscription plan discount',
    props<{
        priceDiscount: number;
        amountTax: number;
        subtotal: number;
    }>()
);

export const setOneTimeFee = createAction(
    '[Payment Flow] set one time fee',
    props<{
        idOneTimeFee: string;
        title: string;
        price: number;
        subtotal: number;
        tax: Tax;
        amountTax: number;
        itemPrice: number;
    }>()
);

export const setOneTimeFeeDiscount = createAction(
    '[Payment Flow] set one time fee discount',
    props<{
        priceDiscount: number;
        amountTax: number;
        subtotal: number;
    }>()
);

export const setProduct = createAction(
    '[Payment Flow] set product',
    props<{
        idProduct: string;
        title: string;
        price: number;
        subtotal: number;
        tax: Tax;
        amountTax: number;
        itemPrice: number;
    }>()
);

export const setProductDiscount = createAction(
    '[Payment Flow] set product discount',
    props<{
        priceDiscount: number;
        amountTax: number;
        subtotal: number;
    }>()
);

export const setEnrollment = createAction(
    '[Payment Flow] set enrollment',
    props<{
        idProduct: string;
        title: string;
        price: number;
        subtotal: number;
        tax: Tax;
        amountTax: number;
        itemPrice: number;
    }>()
);

export const setEnrollmentDiscount = createAction(
    '[Payment Flow] set enrollment discount',
    props<{
        priceDiscount: number;
        amountTax: number;
        subtotal: number;
    }>()
);

export const setCombo = createAction(
    '[Payment Flow] set combo',
    props<{
        idCombo: string;
        title: string;
        price: number;
        isProrated: boolean;
        subtotal: number;
        itemPrice: number;
        oneTimePrice: number;
        recurrentPrice: number;
        subscriptionType: SubscriptionType;
        comboFees: ComboFee[];
    }>()
);

export const setComboDiscount = createAction(
    '[Payment Flow] set combo discount',
    props<{
        priceDiscount: number;
        amountTax: number;
        subtotal: number;
    }>()
);

export const cancelPaymentInfoFlow = createAction(
    '[Payment Flow] cancel payment info flow'
);

export const setSchedule = createAction(
    '[Payment Flow] set schedule',
    props<{ idSchedule: number }>()
);

export const setService = createAction(
    '[Payment Flow] set service',
    props<{ idService: string }>()
);

export const cancelRedirectFlow = createAction(
    '[Payment Flow] cancel redirect flow'
);

export const setCouponInfo = createAction(
    '[Payment Flow] set coupon info',
    props<{ couponInfo: CouponCodeInfo }>()
);

export const cleanCouponInfo = createAction(
    '[Payment Flow] clean coupon info'
);

export const setPaymentProrate = createAction(
    '[Payment Flow] set payment prorate',
    props<{ paymentProrate: PaymentProrate }>()
);

export const cleanPaymentProrate = createAction(
    '[Payment Flow] clean payment prorate'
);

export const clean = createAction(
    '[Payment Flow] clean'
);
