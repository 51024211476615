import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { Enrollment } from '@models/index';
import { RatesEnrollmentService } from '@services/rates/rates-enrollment.service';

@Component({
    selector: 'app-rates-enrollment',
    templateUrl: './rates-enrollment.component.html',
    styleUrls: ['./rates-enrollment.component.scss']
})
export class RatesEnrollmentComponent {
    enrollments$!: Observable<Enrollment[]>;
    isLoading!: Observable<boolean>;

    constructor(enrollmentService: RatesEnrollmentService) {
        this.enrollments$ = enrollmentService.enrollments;
        this.isLoading = enrollmentService.loadingData;
    }
}
